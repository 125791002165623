import { Box, Grid, GridItem, useMediaQuery } from "@chakra-ui/react";
import { useRef } from "react";
import Head from "next/head";
import Layout from "../components/Layout.js";
import HomeSection from "../components/sections/Home.js";
import MobileHome from "../components/sections/MobileHome.js";
import HouseOfIdeasSection from "../components/sections/HouseOfIdeas.js";
import MobileHouseOfIdeas from "../components/sections/MobileHouseOfIdeas.js";
import WhyWeb3Section from "../components/sections/WhyWeb3.js";
import MobileWhyWeb3 from "../components/sections/MobileWhyWeb3.js";
import CarouselSection from "../components/sections/Carousel";
import MobileCarousel from "../components/sections/MobileCarousel";
import styles from "../styles/Home.module.css";

export default function Home() {
  const sectionHeight = 1080;
  const sectionWidth = "100%";
  const [isLargerThan1280] = useMediaQuery("(min-width: 1280px)");

  const scrollToRef = useRef(null);
  const scrollToRefMobile = useRef(null);

  const scrollToRefOpenSea = useRef(null);
  const scrollToRefOpenSeaMobile = useRef(null);

  return (
    <Layout dark={false}>
      <Head>
        <title>Impact Theory Founders Key</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="The best way to improve someone’s life is to introduce them to useful ideas. And the best way to introduce useful ideas is through storytelling."
        />
        <meta
          name="og:description"
          content="The best way to improve someone’s life is to introduce them to useful ideas. And the best way to introduce useful ideas is through storytelling."
        />
        <link rel="icon" href="/favicon.png" />
        <meta
          property="og:title"
          content="Impact Theory Founders Key Official Website"
        />
        <meta property="og:url" content="https://www.founderskey.io" />
        <meta property="og:image" content="./MetaData/og-image.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>

      <main className={styles.main}>
        {/* 1920 x 1080 */}

        <Box className={styles.mainBox}>
          <Grid className={styles.pcGrid}>
            <GridItem w={sectionWidth} zIndex="2">
              <CarouselSection
                scrollToRef={scrollToRef}
                scrollToRefOpenSea={scrollToRefOpenSea}
              />
            </GridItem>
            <GridItem
              w={sectionWidth}
              h="74.01vw"
              zIndex="2"
              ref={scrollToRefOpenSea}
            >
              <HomeSection isOpen="open" />
            </GridItem>
            <GridItem w={sectionWidth} height={{ base: '72vw', md: '1100px', lg: '1000px' }}>
              <WhyWeb3Section />
            </GridItem>
            <GridItem w={sectionWidth} bg="#ffffff" ref={scrollToRef}>
              <HouseOfIdeasSection />
            </GridItem>
            {/* <GridItem w={sectionWidth} h="74.01vw" zIndex="2">
              <HomeSection isOpen="open" />
            </GridItem> */}
          </Grid>
          <Grid className={styles.mobileGrid}>
            <GridItem w={sectionWidth} zIndex="2">
              <MobileCarousel
                scrollToRef={scrollToRefMobile}
                scrollToRefOpenSea={scrollToRefOpenSeaMobile}
              />
            </GridItem>
            <GridItem
              w={sectionWidth}
              zIndex="2"
              ref={scrollToRefOpenSeaMobile}
            >
              <MobileHome />
            </GridItem>
            <GridItem w={sectionWidth}>
              <MobileWhyWeb3 />
            </GridItem>
            <GridItem
              w={sectionWidth}
              h={sectionHeight}
              bg="#ffffff"
              ref={scrollToRefMobile}
            >
              <MobileHouseOfIdeas />
            </GridItem>
          </Grid>
        </Box>
      </main>
    </Layout>
  );
}
