import Footer from "./Footer";
import styles from "../styles/Home.module.css";
import ITNavBar from "./navbar/ITNavBar";
import { Box } from "@chakra-ui/react";

export default function Layout({ children, backgroundColor, dark }) {
  return (
    <div className={styles.container} style={{backgroundColor:backgroundColor}}>
      <Box position="sticky" top="0" left="0" bg="transparent" zIndex={2000}>
        <ITNavBar dark={dark} />
      </Box>
      <Box position="relative" top="-100px">
        <main className={styles.main} style={{margin:"auto"}}>{children}</main>
        <Footer />
      </Box>
    </div>
  );
}
